@font-face {
  font-family: 'gothambook';
  src: url('./assets/fonts/gotham-book-webfont.eot');
  src: url('./assets/fonts/gotham-book-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-book-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-book-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-book-webfont.ttf') format('truetype'),
    url('./assets/fonts/gotham-book-webfont.svg#gothambook') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gotham_blackregular';
  src: url('./assets/fonts/gotham-black-webfont.eot');
  src: url('./assets/fonts/gotham-black-webfont.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/gotham-black-webfont.woff2') format('woff2'),
    url('./assets/fonts/gotham-black-webfont.woff') format('woff'),
    url('./assets/fonts/gotham-black-webfont.ttf') format('truetype'),
    url('./assets/fonts/gotham-black-webfont.svg#gotham_blackregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 140px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background: #f6f7fe;
  --primary-color: #251A60;
  --secondary-color: #00F0AC;
  --lightPurple-color: #CDC6E0;
  --darkPurple-color: #8A81AC;
}

a {
  text-decoration: none;
}

/* For Chrome, Safari, and Edge */
/* input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* For Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

.recharts-surface {
  overflow: visible;
}